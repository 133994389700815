import axiosApi from 'axios';
// import { useSelector } from 'react-redux';

import { paths } from 'src/routes/routes/paths';

// eslint-disable-next-line import/no-cycle
import { useGetRoles, useGetToken, useGetMemberId } from 'src/hooks/useHandleSessions';

// apply base url for axios
export const TOKEN_PREFIX = 'token';

export const frontEndUrl = 'https://test.winbuks.io';

export const PRO_BASE_URL = 'https://api.aizoft.com/';
export const DEV_BASE_URL = 'http://192.168.1.87:8000/';
export const CONTACT_BASE_URL = 'http://192.168.1.:6000';
// export const PRO_BASE_URL = '';
// mmm
const axios = axiosApi.create({
  baseURL: PRO_BASE_URL,
});

const axiosContact = axiosApi.create({
  baseURL: 'https://mail-sender.vingb.com/send/edbffb4e-62b1-4a3c-94af-052f53602300',
});

axios.interceptors.request.use(
  (config) => {
    const { token } = useGetToken(TOKEN_PREFIX);
    const { role } = useGetRoles();
    const memberId = useGetMemberId();

    // const memberId = useGetMemberId('memberId');

    // console.log('memberId', memberId);

    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }

    const accountName = 'Advertising-Account';

    if (role) {
      config.headers[accountName] = memberId?.memberId;
    }

    const handleDashboardApp = () => {
      switch (role) {
        case 'admin':
          return 'admin';
        case 'member':
          return 'member';
        // case 'school_user':
        //   return 'school-user';
        // case 'student':
        //   return 'student';
        default:
          return '';
      }
    };

    if (config && config.url && config.url.includes('/admin')) {
      const parts = config.url.split('/admin');
      config.url = `${parts[0]}/${handleDashboardApp()}${parts[1]}`;
    }
    return config;
  },
  (error) => error
);

axios.interceptors.response.use(
  (response) => response,
  (err) => {
    if (err.response) {
      console.log('err =>', err.response.status);
      // Dispatch an action to set the error response in Redux state
      // store.dispatch(setErrorResponse(err.response));

      if (err.response.status === 401) {
        // Clear session token and redirect to the landing page
        sessionStorage.clear('token');
        window.location.replace(paths.public.landing);
      }
    }

    // Reject the promise with the error
    return Promise.reject(err);
  }
);
export async function get(url, config = {}) {
  return axios.get(url, { ...config }).then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axios.post(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axios.put(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function patch(url, data, config = {}) {
  return axios.patch(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function del(url, config = {}) {
  return axios.delete(url, { ...config }).then((response) => response.data);
}

export async function contactPost(url, data, config = {}) {
  return axiosContact.post(url, { ...data }, { ...config }).then((response) => response.data);
}

// axios.interceptors.request.use(
//   (config) => {
//     const { token } = useGetToken(TOKEN_PREFIX);
//     const { role } = useGetRoles();

//     if (token) {
//       config.headers.authorization = `Bearer ${token}`;
//     }
//     const handleDashboardApp = () => {
//       switch (role) {
//         case 'admin':
//           return 'admin';
//         case 'partner':
//           return 'partner';
//         case 'school_user':
//           return 'school';
//         default:
//           return '';
//       }
//     };

//     if (config && config.url && config.url.includes("/admin")) {
//       const parts = config.url.split("/admin")
//       config.url = ${parts[0]}/${handleDashboardApp()}${parts[1]}
//     }
//     return config;
//   },

//   (error) => error
// );
